
/**=====================
    05. Sidebar CSS Start
==========================**/
@keyframes scaleDisappear {
  0% {
    transform: scale(1);
  }
  60% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}

.page-wrapper{
  .page-body-wrapper{
    .iconsidebar-menu {
      position: $sidebar-position;
      z-index: $sidebar-menu-z-index;
      top: $sidebar-menu-position-top;
      transition: $sidebar-transition;
      &.iconbar-second-close {
        .iconMenu-bar {
          margin-left: $sidebar-margin-left-close;
          .iconbar-mainmenu {
            display: none;
            margin-left: $sidebar-margin-left-close;
          }
        }
        ~ .page-body, ~ footer {
          margin-left: 0;
        }
      }
      &.iconbar-mainmenu-close {
        .iconMenu-bar {
          display: block;
          .iconbar-mainmenu {
            margin-left: $sidebar-main-submenu-margin-close;
            box-shadow: none;
          }
        }
        ~ .page-body, ~ footer {
          margin-left: $sidebar-iconsmenu-width;
        }
      }
      .iconMenu-bar {
        width: $sidebar-iconsmenu-width;
        display: block;
        height: $sidebar-height;
        overflow: auto;
        background-color: $sidebar-background-color;
        transition: 0.3s;
        li{
          position: relative;
          .badge{
            position: absolute;
            top: 10px;
            right: 10px;
          }
        }
        .bar-icons {
          display: block;
          text-align: center;
          padding: $sidebar-bar-icon-padding;
          transition: all 0.3s ease;
          border: 1px solid $light-background;
          border-top: none;
          z-index: $sidebar-bar-icon-z-index;
          letter-spacing: 0.6px;
          a{
            &.bar-icons{
              &:after{
                position: absolute;
                content: "";
                height: 15px;
                width: 15px;
                background-color: #faf8fd;
                top: 40%;
                right: -8px;
                border-radius: 15px;
              }
            }
          }
          span{
            padding-top: 2px;
            display: block;
            color: $black;
            font-weight: 600;
            line-height: 1;
            padding-top: 7px;
          }
          i {
            font-size: $sidebar-bar-icon-font-size;
            vertical-align: middle;
            color: $theme-font-color;
            font-weight: 600;
          }
          svg {
            vertical-align: middle;
          }
        }
        .iconbar-mainmenu {
          position: $sidebar-position;
          top: $sidebar-iconbar-mainmenu-top-space;
          height: $sidebar-height;
          overflow: auto;
          left: $sidebar-iconsmenu-width;
          width: $sidebar-icons-mainmenu-width;
          background-color: $sidebar-background-color;
          box-shadow: $sidebar-shadow;
          transition: all 0.5s ease-in-out;
          display: none;          
          z-index: -1;
          animation: fadeInLeft 1000ms ease-in-out;
          li {
            padding-left: 20px;
            transition: all 0.3s ease;
            &:not(.iconbar-header){
             &:hover{
              letter-spacing: 0.8px;
              transition: all 0.3s ease;
            }
          }           
          &:last-child {
            padding-bottom: 25px;
          }
          a {
            color: lighten($black, 10%);
            padding-top: 15px;
            display: block;
            font-weight: $sidebar-iconbar-mainmenu-font-weight;
          }
          &.active {
            a {
              &.active {
                color: $primary-color;
              }
            }
          }
        }
        .iconbar-header {
         font-size: $sidebar-iconbar-mainmenu-header-font-size;
         color: $sidebar-hover-txt-color;
         padding: $sidebar-iconbar-main-menu-header-padding;
         background-color: lighten($primary-color, 45.5%);
         font-weight: $sidebar-iconbar-main-menu-font-weight;
         a {
           padding-top: 0;
         }
         &.sub-header {
          margin-top: $sidebar-iconbar-main-menu-subheader-margin;
        }
      }
    }
    li {        
      &:not(.open){
        &:hover{
         .bar-icons {
          background-color: lighten($primary-color, 40%);
          transition: all 0.3s ease; 
          svg ,i ,span{
            color: $primary-color;
            transition: all 0.3s ease; 
            animation: scaleDisappear 0.3s;
          }
        } 
      }
    }
    &:active, &:focus, &.open {
      .bar-icons {
        background-color: $primary-color;
        border: 1px solid $primary-color;
        &:before{            
          content: "";
          position: absolute;
          right: 0;
          top: 0;
          height: 85px;
          width: 90px;
          box-shadow: 2px 0px 35px -5px rgba($primary-color, 0.5);
        }
        svg ,i ,span{
          color: $white;
        }
      }
      .iconbar-mainmenu {
        display: block;
        animation: bounceInLeft 1000ms ease-in-out;
      }
    }
  }
}
.iconbar-mainmenu-close {
  display: none !important;
}
}
}
}

// right side chat menu
.right-sidebar {
  top: 97px;
  right: -285px;
  height: 100%;
  position: fixed;
  width: 285px;
  z-index: 99;
  background-color: $white;
  transition: .5s;
  box-shadow: 0px 0px 10px rgba(216, 229, 239, 1);
  &.show{
    right: 0;
    transition: $sidebar-transition;
  }
  .modal-header{
    .modal-title{
      padding-top: 2px;
    }
  }
  .friend-list-search {
    position: relative;
    padding: 20px;
    input {
      color: $dark-gray;
      width: 100%;
      background-color: $light-background;
      border: 1px solid $light-background;
      padding: 10px 15px;
      border-radius: 25px;
      letter-spacing: 1px;
    }
    .fa {
      position: absolute;
      right: 35px;
      top: 34px;
      font-size: 14px;
      color: $theme-font-color ;
    }
  }
  .chat-box{
    .people-list{
      ul {
        padding-top: 20px;
        li{
          position: relative;
        }
      }
    }
  }
  svg{
    width: 16px;
    height: 16px;
    margin-top: 5px;
  }
}

/**=====================
    05. Sidebar CSS Ends
==========================**/



