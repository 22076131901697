/**=====================
     67. internationalization css start
==========================**/
.hdg_main{
  position: relative;
}
.main {
  .langChoice{
    position: absolute;
    right: 50px;
    top: 39px;
    padding: 10px 12px;
    background-color: transparent;
    border-radius: 15px;
  }
}
/**=====================
     67. internationalization CSS end
==========================**/