/**=====================
     46. Form Wizard CSS Start
==========================**/
/*Form Wizard One Start*/

.setup-content, .f1, .step-container{
	.form-group{
		margin-bottom: 15px;
	}
}
.notify-alert{
	.close{
		background-color: transparent;
		color: $white;
		font-size: 20px;
		border: 0;
		top: 10px !important;
	}
}
.form-wizard{
	.form-group{
		margin-bottom: 15px;
	}
	.tab {
		display: none;
	}
	.btn-mb{
		margin-bottom: 30px;
	}
	.step {
		height: 10px;
		width: 10px;
		margin: 0 2px;
		background-color: $primary-color;
		border: none;
		border-radius: 50%;
		display: inline-block;
		opacity: 0.5;
		&.active {
			opacity: 1;
		}
		&.finish {
			background-color: $secondary-color;
		}
	}
	.form-control{
		border-radius: 15px;
	}
	.btn{
		border-radius: 15px;
	}
}
/*Form Wizard One ends*/

/*Form Wizard Two Start*/
.stepwizard {
	display: table;
	width: 100%;
	position: relative;
	margin-bottom: 30px;
	
	.stepwizard-row {
		display: table-row;
		&:before {
			top: 16px;
			bottom: 0;
			position: absolute;
			content: " ";
			width: 100%;
			height: 1px;
			background-color: $light-gray;
		}
		.stepwizard-step {
			display: table-cell;
			text-align: center;
			position: relative;
			p {
				margin-top: 10px;
			}
			button[disabled] {
				opacity: 1 !important;
				filter: alpha(opacity=100) !important;
			}
		}
	}
}
.setup-content{
	.nextBtn{
		border-radius: 15px;
	}
	.form-control{
		border-radius: 15px;
	}
}
/*Form Wizard Two Ends*/


/*Form Wizard Three Start*/
.f1 {
	fieldset {
		display: none;
		input{
			border-radius: 15px;
		}
	}
	.input-error {
		border-color: $danger-color;
	}
	.f1-steps {
		overflow: hidden;
		position: relative;
		margin-top: 30px;
		margin-bottom: 30px;
		.f1-progress {
			position: absolute;
			top: 24px;
			width: 100%;
			height: 1px;
			background: $dark-gray;
			.f1-progress-line {
				height: 1px;
				background: $primary-color;
				width: 16%;
			}
		}
		.f1-step {
			position: relative;
			float: left;
			width: 33.333333%;
			text-align:center;
			&.activated {
				.f1-step-icon {
					background: $white;
					border: 1px solid $primary-color;
					color: $primary-color;
					line-height: 38px;
				}
				p {
					color: $primary-color;
				}
			}
			&.active {
				.f1-step-icon {
					width: 48px;
					height: 48px;
					background: $primary-color;
					font-size: 22px;
					line-height: 48px;
				}
				p {
					color: $primary-color;
				}
			}
			p {
				color: $dark-gray;
			}
			.f1-step-icon {
				display: inline-block;
				width: 40px;
				height: 40px;
				background: $dark-gray;
				font-size: 16px;
				color: $white;
				line-height: 40px;
				border-radius: 50%;
			}
		}
	}
	.f1-buttons {
		text-align: right;
		.btn{
			border-radius: 15px;
		}
	}
}
/*Form Wizard Three ends*/


/*Form Wizard Four Start*/
.wizard-4 {
	.buttonDisabled {
		color: $primary-color;
		background-color: rgba($primary-color, 0.1) !important;	
		cursor: text;
		border: none;
	}
	.action-bar{
		.btn{
			float: right;
			margin: 0 3px;
		}
	}
	.step-container {
		position: relative;
		margin: 0;
		padding: 0;
		border: 0 solid $light-gray;
		overflow: hidden;
		clear: right;
		width: 70%;
		box-sizing: border-box;
		div.content {
			display: block;
			position: absolute;
			float: left;
			height: 295px;
			text-align: left;
			overflow: auto;
			z-index: 1;
			width: 100%;
			box-sizing: border-box;
			clear: both;
		}
	}
	.loader {
		display: none;
	}
	.msg-box {
		position: absolute;
		display: none;
		padding: 5px 10px;
		background-color: $primary-color;
		left: 32%;
		bottom: 30px;
		border-radius: 2px;
		.content {
			padding: 0;
			float: left;
			padding-top: 3px;
			color: $white;
		}
		.btn-close {
			text-shadow: none;
			color: $white;
			margin-left: 10px;
			opacity: 1;
		}
	}
	ul.anchor {
		position: relative;
		display: block;
		float: left;
		list-style: none;
		margin: 0;
		padding: 0;
		border: 0 solid $light-gray;
		background: transparent;
		width: 30%;
		box-sizing: border-box;
		padding-right: 30px;
		li {
			position: relative;
			display: block;
			margin: 0;
			padding: 0;
			padding-bottom: 19px;
			float: left;
			clear: both;
			width: 100%;
			box-sizing: border-box;
			z-index: 0;
			a {
				display: block;
				position: relative;
				float: left;
				margin: 0;
				padding: 10px 17px;
				width: 100%;
				box-sizing: border-box;
				text-decoration: none;
				outline-style: none;
				z-index: 1;
				font-size: 18px;
				small{
					display: block;
					font-size: 14px;
				}
				.stepNumber {
					position: relative;
					float: left;
					width: 30px;
					text-align: center;
					padding: 5px;
					padding-top: 0;
				}
				&.selected {
					color: $white;
					background: $primary-color;
					cursor: text;
					border-radius: 20px;
				}
				&.done {
					position: relative;
					color: $white;
					background: $secondary-color;
					z-index: 99;
					border-radius: 20px;
				}
				&.disabled {
					color: $primary-color;
					background: rgba($primary-color, 0.1);
					cursor: text;
					border-radius: 20px;
					opacity: 1;
				}
				&.error {
					color: $dark-gray;
					background: $pitch-color ;
					border: 1px solid $danger-color;
					&:hover {
						color: $black;
					}
				}
			}
		}
	}
	.form-control,.btn{
		border-radius: 15px;
		&:focus{
			box-shadow: none;
		}
	}
	
}
/*Form Wizard Four Ends*/
/**=====================
    46. Form Wizard CSS Ends
==========================**/
